.collapsingCell {
  padding-bottom: 0;
  padding-top: 0;
  border: 0;
}

.buttonCell {
  padding: 6px;
}

.redRow {
  background-color: #ffebee;
}
