@import 'styles/base';

.firstCell {
  padding: 0 10px;
}

.nameCell {
  padding: 0;
}

.collapsingCell {
  padding-bottom: 0;
  padding-top: 0;
  border: 0;
}

.rating {
  display: flex;

  svg {
    fill: $colorGold;
    font-size: 18px;
    margin-right: 3px;
  }
}

.availability {
  align-items: center;
  display: flex;
  padding: 0;
  text-align: left;

  svg {
    fill: $colorLightGrey;
    margin-left: 10px;
    font-size: 15px;
    margin-top: 3px;
    margin-right: 10px;
  }
}

.availability__low svg {
  fill: $colorOrange;
}

.availability__full svg {
  fill: $colorDarkRed;
}

.availability__high svg {
  fill: $colorGreen;
}

.langoScore {
  display: flex;
  align-items: center;

  svg {
    fill: $colorGreen;
    margin-right: 5px;
    font-size: 18px;
  }
}

.students {
  display: flex;
  align-items: center;

  svg {
    font-size: 18px;
    fill: $colorDarkGrey;
    margin-right: 5px;
  }
}

.copyEmailButton {
  background: none;
  border: none;
  padding: 0;
  font-size: 16px;
  height: 24px;
  cursor: pointer;
}
