@import 'styles/base';

.logoutButton {
  text-transform: none;
  color: $colorRed;
  width: 100%;
}

.wrapper,
.drawer {
  @include lg {
    display: none;
  }
}

.email {
  margin-left: auto;
  font-size: 16px;

  @include md {
    font-size: 18px;
  }
}

.menuText {
  font-size: 16px;

  @include md {
    font-size: 18px;
  }
}
