@import 'styles/base';

.wrapper {
  padding: 0 16px 20px;
  display: flex;
  flex-direction: column;

  @include md {
    flex-direction: row;
    align-items: flex-end;
  }
}

.datePicker {
  margin-right: 15px;

  // Error text
  p {
    position: absolute;
    bottom: -20px;
  }
}

.filterButton {
  margin-top: 10px;
  margin-bottom: 8px;
  width: 75px;
  font-size: 13px;

  @include md {
    font-size: 14px;
  }

  @include lg {
    margin-top: 0;
  }
}
