@import 'styles/base';

.table {
  @include lg {
    display: none;
  }
}

.headerCell {
  @include sm-only {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.sumTable {
  width: 80%;
  margin-left: auto;

  @include md {
    width: 50%;
  }
}

.numberOfClasses {
  font-size: 16px;
  margin-left: 12px;
  display: block;
  margin-bottom: 10px;
}

.number {
  font-weight: bold;
  text-decoration: underline;
}
