@import 'styles/base';

.wrapper {
  padding: 10px;

  @include md {
    padding: 20px;
  }
}

.generalInfoWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.input__generalInfo {
  width: 100%;

  @include md {
    flex-basis: 18%;
    width: 18%;
  }
}

.input__amount {
  width: 100%;

  @include md {
    flex-basis: 12%;
    width: 12%;
  }
}

.input__reference {
  width: 100%;

  @include md {
    flex-basis: 24%;
    width: 24%;
  }
}

.textArea {
  width: 100%;
}

.card {
  margin-bottom: 20px;
}

.cardHeader {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.submitButton {
  min-width: 155px;
}

.errorBox {
  margin-top: 10px;
}

.date {
  margin-top: 1px;

  @include md {
    margin-top: 5px;
  }
}

.autocomplete {
  @include md {
    margin-top: 5px;
  }
}
