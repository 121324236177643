@import 'styles/base';

.wrapper {
  @include lg {
    margin-left: 15px;
  }
}

.box {
  width: 15px;
  height: 5px;
  border: 0.5px solid $colorBlack;
  border-radius: 5px;
  margin-bottom: 1px;

  &__red {
    background-color: $colorRed;
  }

  &__yellow {
    background-color: $colorGold;
  }

  &__green {
    background-color: $colorGreen;
  }
}
