@import 'styles/base';

.collapsingCell {
  padding-bottom: 0;
  padding-top: 0;
  border: 0;
}

.mainCell {
  @include sm-only {
    padding: 5px;
  }
}
