@import 'styles/base';

.wrapper {
  position: relative;
  padding-bottom: 40px;
}

.loader {
  text-align: center;
  width: 100%;
  padding: 10px 0;
  margin-top: 30px;
}

.errorBar {
  margin-top: 10px;
}

.tableWrapper {
  margin-top: 30px;
}

.button {
  margin-top: 20px;
  min-width: 95px;
}

.tableName {
  font-size: 28px;
  margin-bottom: 20px;

  @include lg {
    font-size: 34px;
  }
}
