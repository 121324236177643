.loadingButton,
.actionButton {
  width: 98px;
}

.deactivateButton {
  background: none;
  border: none;
  padding: 0;
  font-size: 16px;
  height: 24px;
  cursor: pointer;
}
