@import 'styles/base';

.topContent h1 {
  font-size: 25px;

  @include md {
    font-size: 30px;
  }
}

.wrapper {
  width: 100%;
  padding: 20px;
  margin: 0 10px;
  outline: 0;

  @include md {
    width: 500px;
  }
}

.buttonsWrapper {
  display: flex;
  margin-top: 20px;
}

.createButton {
  margin-right: 10px;
}

.errorBox {
  margin-top: 10px;
}

.autocomplete {
  margin: 20px 0 30px;
}

.paymentRemark {
  margin-top: 20px;
}
