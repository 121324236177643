@import 'styles/base';

.wrapper {
  @include lg {
    display: none;
  }
}

.headerCell {
  font-size: 16px;
  font-weight: bold;
}

.sumTable {
  width: 200px;
  margin-left: auto;
}
