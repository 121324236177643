@import 'styles/base';

.collapsingCell {
  padding-bottom: 0;
  padding-top: 0;
  border: 0;
}

.row__red {
  background-color: #ffebee;
}

.row__yellow {
  background-color: #fffde7;
}

.nameCell {
  padding-left: 0;
  padding-right: 0;
}

.nameCell__noIcon {
  padding-left: 18px;
}

.nameWrapper {
  display: flex;
  align-items: center;

  svg {
    font-size: 14px;
    margin-right: 5px;
  }
}

.buttonCell {
  padding: 6px 8px;
}

.buttonText {
  text-transform: none;
}

.loadingButton {
  width: 100%;
}

.reactivateButton {
  height: 30px;
}

.button {
  font-size: 16px;
  padding: 6px 16px;

  span {
    height: 24px;
    color: $colorBlack;
  }
}

.copyEmailButton {
  background: none;
  border: none;
  padding: 0;
  font-size: 16px;
  height: 24px;
  cursor: pointer;
}
