@import 'styles/base';

.table {
  display: none;

  @include lg {
    display: table;
  }
}

.sumTable {
  width: 400px;
  margin-left: auto;
}

.numberOfClasses {
  font-size: 16px;
  margin-left: 16px;
  display: block;
  margin-bottom: 10px;
}

.number {
  font-weight: bold;
  text-decoration: underline;
}
