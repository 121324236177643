@import 'styles/base';

.table {
  @include lg {
    display: none;
  }
}

.headerCell {
  font-size: 16px;
  font-weight: bold;
}
