.topContent {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.button {
  margin-top: 12px;
  background-color: #8a7dfe;
  height: 56px;
  font-size: 16px;

  &:hover {
    background-color: #7768fc;
  }
}

.input {
  .Mui-focused {
    border-color: #8a7dfe;
    outline: #8a7dfe auto 1px;
  }
}

.loaderIcon {
  width: 24px !important;
  height: 24px !important;
}
