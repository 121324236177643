@import 'styles/base';

.content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.halfWidth {
  width: 100%;

  @include md {
    width: 49%;
  }
}

.fullWidth {
  width: 100%;
}

.phone {
  width: 100%;
}

.error {
  color: $colorRed;
  margin-left: 14px;
  margin-right: 14px;
  margin-top: 4px;
  font-size: 12px;
}

.button {
  margin-top: 20px;
  width: 100%;
}

.paymentValue {
  margin-top: 10px;
}

.explanation {
  margin-bottom: 5px;
  display: block;
}

.customPayment {
  width: 220px;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
}

.adornment p {
  color: $colorBlack;
}
