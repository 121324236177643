.collapsingCell {
  padding-bottom: 0;
  padding-top: 0;
  border: 0;
}

.numberCell,
.buttonCell {
  padding-left: 10px;
  padding-right: 10px;
}

.trial {
  padding-bottom: 0;
}
