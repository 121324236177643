@import 'styles/base';

.wrapper {
  @include lg {
    display: none;
  }
}

.tableBody {
  border-top: 1px solid rgba(224, 224, 224, 1);
}
