@import 'styles/base';

.wrapper {
  @include lg {
    display: none;
  }
}

.tableBody {
  border-top: 1px solid rgba(224, 224, 224, 1);
}

.title {
  margin-left: 10px;
  margin-bottom: 10px;
  display: block;
}
