@import 'styles/base';

.wrapper {
  align-items: center;
  background: $colorGrey;
  bottom: 0;
  display: flex;
  height: 22px;
  justify-content: center;
  left: 0;
  position: fixed;
  width: 100%;
  font-size: 14px;
}

.wrapper__developmentMode {
  background-color: $colorDarkRed;
  height: 30px;
  font-size: 16px;
}
