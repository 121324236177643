@import 'styles/base';

.availability {
  text-align: center;
  padding: 0;
  width: 20px;
  color: $colorLightGrey;

  svg {
    font-size: 15px;
    margin-top: 5px;
    margin-left: 12px;
  }
}

.rating {
  display: flex;

  svg {
    fill: $colorGold;
    font-size: 18px;
    margin-right: 3px;
  }
}

.availability__low {
  color: $colorOrange;
}

.availability__full {
  color: $colorDarkRed;
}

.availability__high {
  color: $colorGreen;
}

.langoScore {
  display: flex;
  align-items: center;

  svg {
    fill: $colorGreen;
    margin-right: 5px;
    font-size: 18px;
  }
}

.students {
  display: flex;
  align-items: center;

  svg {
    font-size: 18px;
    fill: $colorDarkGrey;
    margin-right: 5px;
  }
}

.copyEmailButton {
  background: none;
  border: none;
  padding: 0;
  font-size: 16px;
  height: 24px;
  cursor: pointer;
}
