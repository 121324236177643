@import 'styles/base';

.header {
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  position: relative;
}

.wrapper {
  width: 100%;
  min-height: 100vh;
  padding-bottom: 50px;
}

.loaderWrapper {
  align-items: center;
  background: rgba($color: #000000, $alpha: 0.8);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  color: $colorWhite;
  z-index: 100;
}

.button {
  width: 100%;
  margin-top: 40px;
}
