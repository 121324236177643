@import 'styles/base';

.wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @include lg {
    flex-direction: row;
  }
}

.buttonsWrapper {
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  @include lg {
    margin-top: 0;
  }
}

.name {
  display: flex;
  margin-bottom: 5px;
  align-items: center;
}

.text {
  margin-bottom: 5px;
}

.remark {
  margin-bottom: 5px;
  padding-right: 20px;
}

.availabilityCircle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: block;
  margin-left: 10px;
  margin-right: 10px;
  background-color: $colorLightGrey;

  @include lg {
    margin-right: 0;
  }
}

.availabilityCircle__low {
  background-color: $colorOrange;
}

.availabilityCircle__full {
  background-color: $colorDarkRed;
}

.availabilityCircle__high {
  background-color: $colorGreen;
}

.button {
  margin-bottom: 5px;
}

.leftContentWrapper {
  @include lg {
    width: 80%;
  }
}
