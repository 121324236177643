@import 'styles/base';

.collapsingCell {
  padding-bottom: 0;
  padding-top: 0;
  border: 0;
}

.mainCell {
  @include sm-only {
    padding: 5px;
  }
}

.checkboxCell {
  padding-left: 0;
  padding-right: 0 !important;
  text-align: center;
}
