@import 'styles/base';

.card {
  width: 140px;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  margin-bottom: 10px;
  display: inline-block;
  border: 1px solid rgba($colorBlue, 0.5);

  &:nth-child(odd) {
    margin-right: 10px;
  }

  @include md {
    &:nth-child(odd) {
      margin-right: 0;
    }

    &:not(:nth-child(3n + 3)) {
      margin-right: 10px;
    }
  }

  @include lg {
    &:nth-child(odd) {
      margin-right: 10px;
    }

    margin-right: 10px;
  }
}

.cardHeader {
  padding: 10px;
  text-align: center;
  color: rgba($colorBlue, 1);
  border-bottom: 1px solid rgba($colorBlue, 0.5);

  span {
    font-size: 16px;
  }
}

.cardContent {
  font-size: 16px;
  display: flex;
  justify-content: center;

  div {
    margin-left: 0;
  }
}
