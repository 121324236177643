@import 'styles/base';

.collapsingCell {
  padding-bottom: 0;
  padding-top: 0;
  border: 0;
}

.nameCell {
  padding-left: 0;
  padding-right: 0;
  width: 50%;
}

.nameWrapper {
  display: flex;
  align-items: center;

  svg {
    font-size: 14px;
    margin-right: 5px;
  }
}

.buttonCell {
  padding: 6px 8px;
  width: 10%;
}

.buttonText {
  text-transform: none;
}

.loadingButton {
  width: 100%;
}

.activateButton {
  height: 30px;
  width: 98px;
}

.button {
  font-size: 16px;
  padding: 6px 16px;

  span {
    height: 24px;
    color: $colorBlack;
  }
}
