.collapsingCell {
  padding-bottom: 0;
  padding-top: 0;
  border: 0;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
}

.uidCell {
  word-break: break-all;
}
