.tooltip {
  font-size: 16px;
  text-align: center;
}

.iconCell {
  padding: 0;
  text-align: center;

  svg {
    margin-top: 3px;
  }
}
