@import 'styles/base';

.wrapper {
  width: 100%;
  padding: 20px;
  outline: 0;

  @include md {
    width: 500px;
  }
}

.buttonsWrapper {
  display: flex;
  margin-top: 20px;
}

.createButton {
  margin-right: 10px;
}

.errorBox {
  margin-top: 10px;
}
