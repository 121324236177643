@import 'styles/base';

.wrapper {
  display: none;

  @include lg {
    display: table;
  }
}

.headerCell {
  font-weight: bold;
  font-size: 18px;
}

.signHeaderCell {
  padding: 0px;
  // width: 10px;
}

.actionsWrapper {
  text-align: center;
  border-top: 1px solid rgba(224, 224, 224, 1);
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  padding-bottom: 6px;
  padding-top: 6px;
  position: relative;
}
