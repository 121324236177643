@import 'styles/base';

.wrapper {
  outline: 0;
}

.content {
  padding: 20px;
}

.card {
  padding: 16px;
  margin-bottom: 20px;
}

.radio {
  &:not(:last-child) {
    margin-bottom: 10px;
  }

  @include md {
    &:not(:last-child) {
      margin-bottom: unset;
    }
  }
}

.submitButton {
  width: 90px;
}

.errorBox {
  margin-top: 20px;
}

.title {
  padding-bottom: 10px;
}
