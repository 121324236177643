@import 'styles/base';

.table {
  display: none;

  @include lg {
    display: table;
  }
}

.headerCell {
  font-weight: bold;
  font-size: 18px;
}
