@import 'fonts';
@import 'base';

body,
div,
a,
ul,
li,
p,
video,
h1,
h2,
h3,
h4,
h5,
h6 {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

::-moz-focus-inner {
  border: 0;
}

::-ms-clear {
  display: none;
}

body {
  font-size: 10px;
  font-family: 'Roboto';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  min-height: 100%;
  position: relative;
}
