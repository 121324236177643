@import 'styles/base';

.wrapper {
  display: none;

  @include lg {
    display: block;
  }
}

.headerCell {
  font-weight: bold;
  font-size: 18px;
}

.sumTable {
  width: 200px;
  margin-left: auto;
}
