@import 'styles/base';

.wrapper {
  padding: 10px;

  @include md {
    padding: 20px;
  }
}

.generalInfoWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.input__generalInfo {
  width: 100%;

  @include md {
    flex-basis: 49%;
    width: 49%;
  }
}

.input__paymentMethodInfo {
  width: 100%;

  @include md {
    flex-basis: 32%;
    width: 32%;
  }
}

.textArea {
  width: 100%;
}

.card {
  margin-bottom: 20px;
}

.cardHeader {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.submitButton {
  min-width: 152px;
}

.errorBox {
  margin-top: 10px;
}
