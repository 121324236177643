@import 'styles/base';

.wrapper {
  display: none;

  @include lg {
    display: flex;
  }
}

.button {
  color: $colorWhite;
}

.logoutButton {
  color: $colorWhite;
  margin-left: auto;
  margin-left: 10px;
}

.rightSide {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.dropdownBody {
  background: $colorWhite;
  width: auto;
  height: auto;
  position: absolute;
  top: 35px;
  left: 0;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  padding: 5px 0;
  z-index: 100;

  a {
    width: 100%;
  }
}

.dropdownWrapper {
  position: relative;
}

.navLinkWrapper {
  padding: 0;
}

.navLink {
  color: $colorBlack;
  height: 100%;
  justify-content: start;
  padding: 6px 16px;
  text-decoration: none;
  width: 100%;
}
