.wrapper {
  position: relative;
  padding-bottom: 10px;
}

.content {
  margin: 30px 0;
}

.content__hasError {
  margin: 10px 0 30px;
}

.loader {
  text-align: center;
  width: 100%;
  padding: 10px 0;
}

.errorBar {
  margin-top: 10px;
}
