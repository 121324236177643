.wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}

.submitButton {
  margin-top: 30px;
}

.errorBox {
  margin-top: 20px;
}

.explanation {
  margin-bottom: 5px;
  display: block;
}
