@import 'styles/base';

.wrapper {
  margin-top: 20px;

  @include md {
    margin-top: 50px;
  }
}

.cards {
  margin: 0 auto;
  width: 290px;

  @include md {
    width: 440px;
  }

  @include lg {
    width: 100%;
    margin: 0;
  }
}
