// Colors
$colorBlack: #000;
$colorWhite: #fff;
$colorRed: #f44336;
$colorDarkRed: #aa2e25;
$colorGrey: #f8f8f8;
$colorOrange: #ff9800;
$colorGold: #daa520;
$colorLightGrey: #d3d3d3;
$colorGreen: #006400;
$colorDarkGrey: #708090;
$colorBlue: #3f51b5;
