@import 'styles/base';

.remarkCell {
  padding-top: 0;
  padding-bottom: 0;
  text-align: left;
  padding-left: 35px;
}

.tooltip {
  font-size: 16px;
  text-align: center;
}

.redRow {
  background-color: #ffebee;
}
