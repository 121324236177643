@import 'styles/base';

.tabs {
  order: 2;

  @include lg {
    order: 1;
  }
}

.tab {
  @include sm-only {
    min-width: 100px;
    font-size: 13px;
  }
}

.tabWrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @include lg {
    flex-direction: row;
  }
}

.buttonWrapper {
  height: 48px;
  display: flex;
  align-items: center;
  order: 1;
  justify-content: center;

  @include lg {
    order: 2;
    justify-content: unset;
    padding-right: 30px;
  }
}

.studentButton {
  margin-left: 10px;
}
