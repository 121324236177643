@import 'styles/base';

.row__red {
  background-color: #ffebee;
}

.row__yellow {
  background-color: #fffde7;
}

.signCell {
  width: 19px;
  height: 100%;
  padding: 0;
  position: relative;

  svg {
    font-size: 16px;
    position: absolute;
    left: 14px;
    top: 14px;
  }
}

.button {
  text-transform: none;
  width: 100%;
}

.loadingButton {
  width: 100%;
}

.reactivateButton {
  height: 30px;
}

.flagWrapper {
  padding: 0 5px;

  svg {
    margin-left: 6px;
    margin-top: 2px;
    font-size: 16px;
  }
}

.tooltip {
  font-size: 16px;
  text-align: center;
}

.button {
  font-size: 16px;
  padding: 6px 16px;
  span {
    height: 24px;
    color: $colorBlack;
  }
}

.copyEmailButton {
  background: none;
  border: none;
  padding: 0;
  font-size: 16px;
  height: 24px;
  cursor: pointer;
}
