.remarkCell {
  padding: 0;
  padding-left: 10px;

  svg {
    margin-top: 3px;
  }

  .flag {
    font-size: 16px;
  }

  .flag__withRemark {
    margin-right: 3px;
  }
}

.tooltip {
  font-size: 16px;
  text-align: center;
}
